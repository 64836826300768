import { createApp } from 'vue'
import App from './App.vue'

import Antd from 'ant-design-vue'
import router from './router/index.js'
import '../style/frame.css'
import 'ant-design-vue/dist/antd.css'
const app = createApp(App)
app.use(router)     //挂载函数
app.use(Antd)
app.mount('#app')  //挂载界面
