import { createRouter,createWebHashHistory } from 'vue-router'
const routes = [
    {
        //主界面
        path:'/',       //打开默认
        name:'test',   //子明名
        component:()=>import(/*webpackChunkName:'frame'*/'@/page/frame.vue')//
    }
]

//创建路由实力并传递routers配置并传递 
const router = createRouter({
    //内部提供了history模块的实现，我们这里使用hash模式
    history: createWebHashHistory(), //地址后面会加#哈希值
    routes //routes:routes的缩写
})

export default router
