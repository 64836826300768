<template>
  <!---所有的子组件都走当前这个渲染--->
  <router-view/>
</template>

<script>
</script>

<style>
</style>
